import cn from 'classnames';
import Link from 'next/link';
import { useRouter } from 'next/router';
import React from 'react';
import type { FC } from 'react';

import s from './PageTab.module.scss';

type Props = {
  title: string;
  href?: string;
  onClick?: () => void;
  selected?: boolean;
  scroll?: boolean;
};

const PageTab: FC<Props> = ({
  title,
  href,
  onClick,
  selected = false,
  scroll = true,
}) => {
  const router = useRouter();

  const _selected = selected ?? router.asPath === href;

  const inner = (
    <a
      className={cn(s.inner, (href || onClick) && s.hasLink)}
      onClick={onClick}>
      {title}
    </a>
  );

  return (
    <div
      className={cn(s.root, _selected && s.selected)}
      suppressHydrationWarning>
      {href ? (
        <Link href={href} scroll={scroll}>
          {inner}
        </Link>
      ) : (
        <>{inner}</>
      )}
    </div>
  );
};

export default PageTab;
