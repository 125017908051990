import cn from 'classnames';
import React from 'react';
import type { FC, Ref } from 'react';

import s from './PageTabBar.module.scss';

type Props = {
  center?: boolean;
  classNames?: {
    root?: string;
  };
};

const PageTabBar: FC<Props> = ({
  center = false,
  children,
  classNames = {
    root: '',
  },
}) => (
  <div className={cn(s.root, center && s.center, classNames.root)}>
    <div className={s.border} />
    {children}
  </div>
);

export default PageTabBar;
