import cn from 'classnames';
import hash from 'object-hash';
import Image from 'next/image';
import { useRouter } from 'next/router';
import React, { Fragment, useEffect, useMemo, useState } from 'react';
import type { FC } from 'react';

import Alert from 'core/components/Alert';
import Button from 'core/components/Button';
import PageTabBar from 'components/PageTabBar';
import PageTab from 'components/PageTab';
import Skeleton, { SkeletonVariant } from 'core/components/Skeleton';
import Spinner from 'core/components/Spinner';
import EditIcon from 'core/icons/EditIcon';
import { ColorVariant } from 'core/utils';
import { HttpMethod } from 'core/utils/http';
import { replaceAll } from 'core/utils/strings';

import PageContainer from 'components/PageContainer';
import SEO from 'components/SEO';
import CreatorLink from 'components/pages/profile/CreatorLink';
import {
  DEFAULT_COVER_PHOTO_URL,
  DEFAULT_PROFILE_PICTURE_URL,
  META_DESCRIPTION_MAX_CHARS,
  OG_IMAGE_DOMAIN,
} from 'config';
import type { ProfileApiResponse } from 'pages/api/profile/[username]/index';
import useProfile from 'hooks/data/useProfile';
import useUser from 'hooks/data/account/useUser';
import useApi from 'hooks/useApi';
import { accountProfileRoute, profileRoute, profileViewApiRoute } from 'routes';

import s from './ProfilePage.module.scss';

enum Tab {
  Links = 'links',
  About = 'about',
}

type Props = {
  staticProfile: ProfileApiResponse;
};

const ProfilePage: FC<Props> = ({ staticProfile }) => {
  const router = useRouter();
  const { data: apiProfile } = useProfile(router.query.username as string);
  const { data: userData } = useUser();
  const [selectedTab, setSelectedTab] = useState<Tab>(Tab.Links);
  const { request } = useApi('profileView');

  const profile = apiProfile || staticProfile;

  const ogImage = useMemo(
    () =>
      `https://${OG_IMAGE_DOMAIN}/${profile?.user?.username}/${hash({
        uuid: profile?.user?.uuid,
        username: profile?.user?.username,
        profilePictureUrl: profile?.user?.profilePictureUrl,
        coverPhotoUrl: profile?.user?.coverPhotoUrl,
        isCreator: profile?.user?.isCreator,
      })}.png`,
    [profile]
  );

  useEffect(() => {
    if (ogImage) {
      const img = document.createElement('img');
      img.src = ogImage;
    }
  }, [ogImage]);

  useEffect(() => {
    if (profile) {
      if (profile?.user?.isCreator || profile?.user?.isCreatorApplicant) {
        setSelectedTab(Tab.Links);

        (async () => {
          try {
            await request({
              url: profileViewApiRoute(profile.user.username),
              method: HttpMethod.Post,
            });
          } catch (err) {
            console.log(err);
            // fail silently
          }
        })();
      } else {
        setSelectedTab(Tab.About);
      }
    }
  }, [profile]);

  if (profile?.error) {
    return (
      <PageContainer fullWidth={true}>
        <SEO title="Error" />
        <Alert variant={ColorVariant.Danger}>{profile.error.message}</Alert>
      </PageContainer>
    );
  }

  return (
    <PageContainer fullWidth={true}>
      <SEO
        title={
          profile?.user
            ? `${profile?.user?.name} (${profile?.user?.username})`
            : 'View my profile'
        }
        description={replaceAll(
          (profile?.user?.about || '').slice(0, META_DESCRIPTION_MAX_CHARS),
          '\n',
          ' '
        ).trim()}
        ogImage={ogImage}
        canonical={profileRoute(profile?.user?.username)}
      />

      <div className={s.coverPhoto}>
        {profile ? (
          <Image
            alt={`${profile.user.name} (${profile.user.username}) cover photo`}
            height="262"
            layout="intrinsic"
            objectFit="contain"
            objectPosition="center"
            priority={true}
            quality={100}
            src={profile.user.coverPhotoUrl || DEFAULT_COVER_PHOTO_URL}
            width="1048"
          />
        ) : (
          <Skeleton classNames={{ root: s.coverPhotoFallback }} />
        )}
      </div>
      <div className={s.profilePicture}>
        {profile ? (
          <Image
            alt={`${profile.user.name} (${profile.user.username}) profile picture`}
            className={s.profilePictureImg}
            layout="fill"
            priority={true}
            src={profile.user.profilePictureUrl || DEFAULT_PROFILE_PICTURE_URL}
          />
        ) : (
          <Skeleton variant={SkeletonVariant.Circle} width="100%" />
        )}
      </div>
      <div className={s.title}>
        <h1 className={s.name}>
          {profile ? (
            <>
              <span>{profile.user.name}</span>
              {profile.user.isCreator && (
                <span className={s.verified}>
                  <Image src="/img/verified.svg" alt="Verified" layout="fill" />
                </span>
              )}
            </>
          ) : (
            <Skeleton classNames={{ root: s.nameSkeleton }}>
              . . . . . . . . . . . . . . . . .
            </Skeleton>
          )}
        </h1>
        <div className={s.status}>
          {profile ? (
            <>{profile.user.status}</>
          ) : (
            <Skeleton classNames={{ root: s.statusSkeleton }}>
              . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . .
            </Skeleton>
          )}
        </div>
      </div>

      {profile?.user?.isCreatorApplicant && (
        <div className={s.creatorApplicantAlert}>
          <Alert
            classNames={{ root: s.creatorApplicantAlertRoot }}
            variant={ColorVariant.Danger}>
            This creator is awaiting review.
          </Alert>
        </div>
      )}

      {userData?.user?.uuid === profile?.user?.uuid && (
        <div className={s.editProfileButton}>
          <Button
            href={accountProfileRoute()}
            icon={EditIcon}
            subtle={true}
            text="Edit Profile"
          />
        </div>
      )}

      {!profile && (
        <div className={s.loading}>
          <Spinner />
        </div>
      )}

      {profile && (
        <PageTabBar center={true}>
          {(profile.user.isCreator || profile.user.isCreatorApplicant) && (
            <PageTab
              title="Links"
              onClick={() => setSelectedTab(Tab.Links)}
              selected={selectedTab === Tab.Links}
            />
          )}
          <PageTab
            title="About"
            onClick={() => setSelectedTab(Tab.About)}
            selected={selectedTab === Tab.About}
          />
        </PageTabBar>
      )}

      {(profile?.user?.isCreator || profile?.user?.isCreatorApplicant) && (
        <div
          className={cn(
            s.tab,
            s.linksTab,
            selectedTab === Tab.Links && s.active
          )}>
          {profile.user?.creator?.links?.length > 0 ? (
            <div className={s.links}>
              {profile?.user?.creator?.links?.map((link, i) => (
                <CreatorLink key={i} creatorLink={link} />
              ))}
            </div>
          ) : (
            <div className={s.noLinks}>
              This creator has not added any links yet.
            </div>
          )}
        </div>
      )}

      {profile && (
        <div
          className={cn(
            s.tab,
            s.aboutTab,
            selectedTab === Tab.About && s.active
          )}>
          {profile.user.about ? (
            <div className={s.aboutText}>
              {profile.user.about.split('\n').map((item, i) => {
                return (
                  <Fragment key={i}>
                    {item}
                    <br />
                  </Fragment>
                );
              })}
            </div>
          ) : (
            <div className={s.noAbout}>
              This{' '}
              {profile.user.isCreator || profile.user.isCreatorApplicant
                ? 'creator'
                : 'user'}{' '}
              has not added any info about themselves.
            </div>
          )}
        </div>
      )}
    </PageContainer>
  );
};

export default ProfilePage;
