export const replaceAll = (
  origStr: string,
  str: string | RegExp,
  newStr: string
): string => {
  // If a regex pattern
  if (Object.prototype.toString.call(str).toLowerCase() === '[object regexp]') {
    return origStr.replace(str, newStr);
  }

  // If a string
  return origStr.replace(new RegExp(str, 'g'), newStr);
};
