import React from 'react';
import type { FC } from 'react';

import SvgIcon from '../components/SvgIcon';
import type { IconProps } from '../components/SvgIcon';

const EditIcon: FC<IconProps> = ({ className, color }) => (
  <SvgIcon>
    <path
      fill={color}
      className={className}
      d="M14.7081581,4.34311609 L19.6504264,9.26380263 L4.944,24 L2.84217094e-14,24 L2.84217094e-14,18.936375 L14.7081581,4.34311609 Z M20.2978078,0.327816554 L23.6300306,3.72937088 C24.0910519,4.20368613 24.0954153,4.71490438 23.6431208,5.26302565 L21.0790352,7.83230185 L16.1594738,2.90313799 L18.7230873,0.359551323 C19.1425916,-0.0249620589 19.6674984,-0.0355403154 20.2978078,0.327816554 Z"
    />
  </SvgIcon>
);

export default EditIcon;
