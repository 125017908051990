import React from 'react';
import type { FC } from 'react';

import CreatorLinkModel from 'core/models/CreatorLink';

import { PLATFORM_DOMAIN_NAME_MAP } from 'config';

import s from './CreatorLink.module.scss';

type Props = {
  creatorLink: CreatorLinkModel;
};

const CreatorLink: FC<Props> = ({ creatorLink }) => {
  const platform = PLATFORM_DOMAIN_NAME_MAP.find((platform) =>
    creatorLink.url.toLowerCase().includes(platform.domain.toLowerCase())
  );

  return (
    <div className={s.root}>
      <a
        className={s.link}
        href={creatorLink.url}
        rel="noreferrer"
        target="_blank">
        <div
          className={s.dot}
          style={{ backgroundColor: platform?.color || '#333' }}
        />
        <div className={s.name}>{creatorLink.name}</div>
      </a>
    </div>
  );
};

export default CreatorLink;
