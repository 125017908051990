import useSWR from 'swr';
import type { SWRConfiguration } from 'swr';

import fetcher from 'core/utils/fetcher';

import type { ProfileApiResponse } from 'pages/api/profile/[username]';
import { profileApiRoute } from 'routes';

const useProfile = (username: string, options?: SWRConfiguration) => {
  return useSWR<ProfileApiResponse>(
    username ? profileApiRoute(username) : null,
    fetcher,
    options
  );
};

export default useProfile;
