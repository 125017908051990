import type { GetStaticPaths, GetStaticProps } from 'next';

import type { AnyObject } from 'core/utils';
import Rds, { RdsSource } from 'core/utils/aws/rds';

import ProfilePage from 'components/pages/profile/ProfilePage';
import { RDS_CONFIG } from 'config';
import { ProfileApiResponse, _get } from 'pages/api/profile/[username]';
import { FormError } from 'core/utils/forms';
import { errorToJSON, NotFoundApiError } from 'utils/api';

export const getStaticPaths: GetStaticPaths = async () => {
  return {
    paths: [],
    fallback: 'blocking',
  };
};

export const getStaticProps: GetStaticProps = async ({ params }) => {
  const db = new Rds(RDS_CONFIG);

  const username = params.username as string;
  let profile: ProfileApiResponse;

  try {
    db.connect(RdsSource.Reader);

    profile = await _get(db, username);
  } catch (err) {
    console.log(err);

    profile = {
      error: errorToJSON(err),
    };

    if (err instanceof NotFoundApiError) {
      return { notFound: true };
    }
  } finally {
    db.disconnect();
  }

  return {
    props: {
      staticProfile: profile,
    },
    revalidate: 1,
  };
};

export default ProfilePage;
